import React, { useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import { useFilterSearchStore } from 'stores/filter-search'

import { SearchBar } from 'components/SearchBar'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'

import { useAPIGetSearchResult } from 'api/search'

import type {
  FilterSearchType,
  SearchResultItem,
  SortType,
} from 'types/search-result'

import CardResult from './CardResult'
import FilterSearch from './FilterSearch'

import { Spinner } from '@wartek-id/spinner'

import { SEARCH_PAGE } from 'constants/routes'
import {
  trackerClickSearchBar,
  trackerGetSearchResult,
  trackerPageviewSearchPage,
} from 'utils/gaTracker'
import { ShootTracker } from 'api/tracker'

const SearchPage = () => {
  const { query, push } = useRouter()

  const filters = useFilterSearchStore((state) => state.filters)
  const setFilters = useFilterSearchStore((state) => state.setFilters)

  const { data: dataSearchResult, isLoading } = useAPIGetSearchResult(filters, {
    enabled: !!filters.keyword,
  })

  const updateFilters = useCallback(
    (query) => {
      const {
        keyword,
        locationId,
        locationName,
        categoryId,
        sort,
        latitude,
        longitude,
      } = query
      setFilters({
        keyword: (keyword as string) ?? null,
        locationId: (locationId as string) ?? null,
        locationName: (locationName as string) ?? null,
        categoryId: (categoryId as string) ?? null,
        sort: (sort as SortType) ?? 'RELEVANCE',
        latitude: (latitude as string) ?? null,
        longitude: (longitude as string) ?? null,
      })
    },
    [setFilters]
  )

  const handleChangeFilter = ({ id, value, name = null }) => {
    const newFilterValue: FilterSearchType = {
      ...filters,
      [id]: value,
    }

    /* handling location id */
    if (id === 'locationId') {
      newFilterValue['locationName'] = name
    }

    if (typeof query.keyword !== 'undefined') {
      var filter_and_sort_dict = newFilterValue
      delete filter_and_sort_dict.latitude
      delete filter_and_sort_dict.longitude

      ShootTracker('funnel_search_filter_product', {
        key: 'filter_and_sort_dict',
        value: JSON.stringify(filter_and_sort_dict),
      })
    }

    push(SEARCH_PAGE(newFilterValue))
  }

  const trackerEventSearchPage = () => {
    ShootTracker('visit_page')
  }

  const handleInputSearch = () => {
    ShootTracker('funnel_search_click')
  }

  const handleSubmit = (keyword: string | string[]) => {
    ShootTracker('funnel_search_submit', {
      key: 'keyword',
      value: keyword,
    })
  }

  const trackerEventSearchResult = (param: SearchResultItem[]) => {
    const mitraDict = Object.assign(
      {},
      param.map((data) => data.mitra)
    )
    const numberOfMitra = param.length

    const numberOfProductDict = param.reduce(function (map, data) {
      map[data.mitra] = data.totalProducts
      return map
    }, {})

    const numberOfTotalProduct = param.reduce((prev, current) => {
      return prev + current.totalProducts
    }, 0)

    ShootTracker(
      'funnel_search_get_result',
      {
        key: 'keyword',
        value: query.keyword,
      },
      {
        key: 'mitra_dict',
        value: JSON.stringify(mitraDict),
      },
      {
        key: 'number_of_mitra',
        value: numberOfMitra.toString(),
      },
      {
        key: 'number_of_product_dict',
        value: JSON.stringify(numberOfProductDict),
      },
      {
        key: 'number_of_total_product',
        value: numberOfTotalProduct.toString(),
      }
    )
  }

  useEffect(() => {
    trackerPageviewSearchPage()
    trackerEventSearchPage()
  }, [])

  useEffect(() => {
    updateFilters(query)
  }, [query, updateFilters])

  /* GA tracker every search result is returned  */
  useEffect(() => {
    if (dataSearchResult !== undefined && filters.locationName !== null) {
      trackerGetSearchResult({
        filter: filters,
        result: dataSearchResult?.data?.data,
      })

      trackerEventSearchResult(dataSearchResult?.data?.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSearchResult])

  return (
    <>
      <Header />
      <div className="container mx-auto px-5 sm:px-20 mt-24">
        <SearchBar
          defaultValue={query.keyword}
          handleSubmit={(e) => {
            handleChangeFilter({
              id: e.id,
              value: e.value,
            })
            handleSubmit(e.value)
            trackerClickSearchBar(filters)
          }}
          handleInputSearch={handleInputSearch}
        />
        <FilterSearch
          className="flex flex-col md:gap-2 md:grid md:grid-cols-2 lg:flex lg:flex-row mt-5 md:mt-3 lg:mt-10"
          handleChangeFilter={handleChangeFilter}
        />
        {isLoading && (
          <div className="flex justify-center pt-20 pb-48 mt-3 ">
            <Spinner size="md" />
          </div>
        )}
        {!isLoading && (
          <div className="flex py-2 mt-3 flex-col">
            {dataSearchResult?.data?.data?.length > 0 ? (
              dataSearchResult?.data?.data.map((mitra, indexMitra) => (
                <CardResult data={mitra} key={indexMitra} />
              ))
            ) : (
              <div className="mt-[16px] text-[16px] pb-48">
                Hasil tidak ditemukan. Cek kembali kata kunci pencarian Anda
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  )
}

export default SearchPage
