import React from 'react'
import { Button } from '@wartek-id/button'
import { BASE_URL_SIPLAH } from 'constants/app'
import { trackerClickHomePage } from 'utils/gaTracker'

export const Header = () => {
  return (
    <div className="shadow fixed top-0 w-screen bg-white z-10">
      <div className="container mx-auto flex md:px-20 py-3 h-[81px] items-start">
        <img
          src="/assets/images/dikbud.png"
          alt="dikbud"
          className="mr-2 md:mr-5 h-full"
        />
        <img
          src="/assets/images/siplah-logo3.png"
          alt="siplah"
          className="h-full"
        />
        <div className="flex-grow"></div>
        <a href={BASE_URL_SIPLAH} className="my-auto mr-1">
          <Button
            color="white"
            size="sm"
            className="h-[38px]"
            onClick={() => trackerClickHomePage()}
          >
            <span className="text-xs md:text-sm">Kembali ke beranda</span>
          </Button>
        </a>
      </div>
    </div>
  )
}

export default Header
