export interface GATrackEventArgs {
  eventName: Gtag.EventNames | string
  eventParams: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
  sendDefaultParams?: boolean
}

const getDefaultTrackerData = () => {
  return {
    timestamp: new Date(),
    url: window.location.href,
    application_version: 'SIPLAH SEARCH v1.0',
    screen_name: 'SEARCH_RESULT',
    product: 'Siplah-Search',
    page_title: 'SEARCH_RESULT',
  }
}

export const GATrackEvent = ({ eventName, eventParams }: GATrackEventArgs) => {
  if (typeof gtag !== 'undefined') {
    gtag('event', eventName, {
      ...getDefaultTrackerData(),
      ...eventParams,
    })
  }
}
