import { SearchResultItem, FilterSearchType } from 'types/search-result'
import { GATrackEvent } from 'utils/googleAnalytics'

interface TrackerGetSearchResultParams {
  filter: FilterSearchType
  result: SearchResultItem[]
}

interface TrackerClickSearchResultParams {
  keyword: string
  mitra: string
  total_product: number
}

const getValueSortPrice = (sort) => {
  switch (sort) {
    case 'PRICE_ASC':
      return 'termurah'
    case 'PRICE_DESC':
      return 'termahal'
    default:
      return 'paling sesuai'
  }
}

export const trackerGetSearchResult = (param: TrackerGetSearchResultParams) => {
  const mock = {
    latitude: param.filter.latitude ?? 'null',
    longitude: param.filter.longitude ?? 'null',
    mitra_dict: JSON.stringify(
      Object.assign(
        {},
        param.result?.map((d) => d.mitra)
      )
    ),
    number_of_mitra: param.result?.length,
    number_of_product_dict: JSON.stringify(
      param.result?.reduce(function (map, d) {
        map[d.mitra] = d.products.length
        return map
      }, {})
    ),
    number_of_total_product: param.result?.reduce(function (total, d) {
      total = total + d.products.length
      return total
    }, 0),
    keyword: param.filter.keyword,
    filter_and_sort_dict: JSON.stringify({
      location: param.filter.locationName ?? 'null',
      sort_price: getValueSortPrice(param.filter.sort),
      category_id: param.filter.categoryId,
    }),
  }

  GATrackEvent({
    eventName: 'GET_SEARCH_RESULT',
    eventParams: mock,
  })
}

export const trackerClickSearchResult = (
  param: TrackerClickSearchResultParams
) => {
  const mock = {
    keyword: param.keyword,
    mitra: param.mitra,
    number_of_total_product: param.total_product,
  }

  GATrackEvent({
    eventName: 'CLICK_SEARCH_RESULT',
    eventParams: mock,
  })
}

export const trackerClickSearchBar = (param: FilterSearchType) => {
  const mock = {
    latitude: param.latitude ?? 'null',
    longitude: param.longitude ?? 'null',
  }
  GATrackEvent({
    eventName: 'CLICK_SEARCH_BAR',
    eventParams: mock,
  })
}

export const trackerClickHomePage = () => {
  return GATrackEvent({
    eventName: 'CLICK_HOME_PAGE_FROM_SEARCH_RESULT',
    eventParams: {},
  })
}

export const trackerPageviewSearchPage = () => {
  return GATrackEvent({
    eventName: 'page_view',
    eventParams: {},
  })
}
