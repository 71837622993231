import React, { KeyboardEvent, useEffect, useState } from 'react'

import { Text } from '@wartek-id/text'
import { Icon } from '@wartek-id/icon'
import { Button } from '@wartek-id/button'

import styles from './SearchBar.module.css'

import clsx from 'clsx'

interface Props {
  handleInputSearch: Function
  handleSubmit: Function
  defaultValue: string | string[]
}

export const SearchBar = ({
  defaultValue,
  handleSubmit,
  handleInputSearch,
}: Props) => {
  const [value, setValue] = useState(null)
  const [error, setError] = useState(false)
  const [isTyping, setIsTyping] = useState(false)

  const onClickSearch = () => {
    if (value === null) {
      return
    }

    let keyword = value.trim()
    setIsTyping(false)

    if (keyword === '') {
      setError(true)
    } else {
      setError(false)
      handleSubmit({
        id: 'keyword',
        value: keyword,
      })
    }
  }

  const handleEnter = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === 13 || e.key === 'enter') {
      onClickSearch()
    }
  }

  const onInputSearch = () => {
    handleInputSearch()
  }

  const handleChange = (e) => {
    const value = e.target.value
    if (value === '') {
      setIsTyping(false)
    } else {
      setIsTyping(true)
    }
    setValue(e.target.value)
  }

  useEffect(() => {
    if (defaultValue === undefined) {
      setError(true)
    } else {
      setValue(defaultValue)
      setError(false)
    }
  }, [defaultValue])

  return (
    <div className="text-center md:mx-auto mx-0 justify-start md:justify-center flex py-4">
      <div className="flex-grow lg:flex-grow-0">
        <div
          className={clsx(
            error && styles.errorBox,
            error && !isTyping && styles.errorBackground,
            'lg:w-[620px] border border-gray-400 rounded px-3 flex h-[46px]'
          )}
        >
          <input
            placeholder="Ketik jenis barang di sini"
            onChange={handleChange}
            onClick={onInputSearch}
            value={value}
            className="focus:outline-none flex-grow bg-transparent text-[15px] w-full md:w-auto"
            maxLength={50}
            onKeyDown={handleEnter}
            data-testid="input-search"
          />
          {value && (
            <img
              onClick={() => setValue('')}
              src="/assets/icons/clear.svg"
              alt="clear"
              className="my-auto cursor-pointer"
              data-testid="btn-clear"
            />
          )}
        </div>
        {error && (
          <div className={clsx('text-left', styles.errorText)}>
            Masukkan kata kunci pencarian
          </div>
        )}
      </div>
      <Button
        className="ml-2 flex items-center mb-0 h-[46px]"
        onClick={onClickSearch}
        data-testid="btn-search"
      >
        <Icon as="i" color="inverse" fontSize="small">
          search
        </Icon>
        <Text color="inverse" variant="action" className="ml-1">
          Cari
        </Text>
      </Button>
    </div>
  )
}

export default SearchBar
