import { AxiosResponse } from 'axios'
import { api } from 'configs/api'
import { GET_SEARCH } from 'constants/api'
import { useQuery, UseQueryOptions } from 'react-query'
import type { APIResponse } from 'types/api'
import type { FilterSearchType } from 'types/search-result'
import type { SearchResultItem } from 'types/search-result'

export function useAPIGetSearchResult(
  filters: FilterSearchType,
  queryOpts?: UseQueryOptions<AxiosResponse<APIResponse<SearchResultItem[]>>>
) {
  return useQuery<AxiosResponse<APIResponse<SearchResultItem[]>>>(
    ['get-search-result', filters],
    () =>
      api().get(GET_SEARCH, {
        params: {
          keyword: filters.keyword,
          locationId: filters.locationId,
          categoryId: filters.categoryId,
          sort: filters.sort,
          latitude: filters.latitude,
          longitude: filters.longitude,
        },
      }),
    queryOpts
  )
}
