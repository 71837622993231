import { Button } from '@wartek-id/button'
import CardProduct from 'app/SearchPage/CardProduct'
import React from 'react'
import { SearchResultItem } from 'types/search-result'
import { trackerClickSearchResult } from 'utils/gaTracker'
import { useFilterSearchStore } from 'stores/filter-search'
import { ShootTracker } from 'api/tracker'

interface Props {
  data: SearchResultItem
}

const CardResult = ({ data }: Props) => {
  const filters = useFilterSearchStore((state) => state.filters)

  const onClickKunjungi = () => {
    ShootTracker(
      'funnel_search_click_search_result',
      {
        key: 'keyword',
        value: filters.keyword,
      },
      {
        key: 'marketplace_name',
        value: data.mitra,
      }
    )
    trackerClickSearchResult({
      keyword: filters.keyword,
      mitra: data.mitra,
      total_product: data.products.length,
    })
  }

  return (
    <div className="flex flex-col shadow border mb-5 px-[24px] py-4 rounded-[8px]">
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-row flex-grow">
          <div className="w-[9rem] h-[3.5rem] grid justify-items-stretch items-center">
            <a
              href={data.linkMitra}
              target="_blank"
              rel="noreferrer"
              onClick={onClickKunjungi}
            >
              <img
                className="max-w-[8rem] max-h-[3.5rem] justify-self-center"
                src={data.imgMitra ?? '/assets/images/siplah-logo3.png'}
                alt="logo mitra"
              />
            </a>
          </div>
          <div className="flex flex-col px-2 mt-[7px] mb-[23px]">
            <div className="font-semibold text-[15px]">{data.mitra}</div>
            <div className="text-[14px]">
              {data.totalProducts} barang ditemukan
            </div>
          </div>
        </div>
        <div className="mt-0 md:mt-[14px]">
          <Button
            className="md:ml-2 flex items-center px-5 w-full mb-4 sm:mb-0"
            color="black"
            size="sm"
          >
            <a
              href={data.linkMitra}
              target="_blank"
              rel="noreferrer"
              onClick={onClickKunjungi}
            >
              Kunjungi Mitra
            </a>
          </Button>
        </div>
      </div>
      <div className="flex flex-no-wrap max-w-screen-sm md:max-w-full overflow-auto md:grid md:grid-cols-5 gap-6">
        {data.products.map((product, i, { length }) => (
          <CardProduct data={product} key={i} isLast={i + 1 === length} />
        ))}
      </div>
      <div className="pt-0 md:pt-4 text-center md:text-right text-[12px] text-gray-500">
        Kunjungi Mitra untuk melihat seluruh barang
      </div>
    </div>
  )
}

export default CardResult
