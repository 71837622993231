import clsx from 'clsx'
import React from 'react'
import { ProductItem } from 'types/search-result'
import { formatter } from 'utils/currency'

interface Props {
  isLast?: boolean
  data: ProductItem
}

const CardProduct = ({ data, isLast }: Props) => {
  const classes = clsx(
    'pr-6 mb-5 md:mb-0 md:border-b-0 min-w-[10.5rem] md:min-w-0',
    !isLast && 'border-gray-300 border-r-[0.5px] '
  )

  return (
    <div className={classes}>
      <div className="flex flex-col">
        <p className="truncate font-bold text-[14px]">{data.name}</p>
        <div className="text-[12px]">{data.merchant}</div>
        <div className="font-semibold text-[12px]">
          {formatter.format(data.price)}
        </div>
        <div className="text-[12px]">{data.locationName}</div>
        {data.ratingProduct > 0 && (
          <div className="text-[12px] flex items-center">
            <img
              src="/assets/icons/star.svg"
              alt="star"
              className="my-auto mr-1"
            />
            <span>{data.ratingProduct}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default CardProduct
