export const SEARCH_PAGE = (params) => {
  const payload = []
  const allowedParams = [
    'keyword',
    'locationId',
    'locationName',
    'categoryId',
    'sort',
    'latitude',
    'longitude',
  ]

  for (const obj in params) {
    const value = params[obj]
    if (allowedParams.includes(obj)) {
      if (value != undefined && value != '') {
        payload.push(`${obj}=${params[obj]}`)
      }
    }
  }

  return `/search?${payload.join('&')}`
}
