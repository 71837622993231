import React, { useState } from 'react'
import clsx from 'clsx'

import type { SortType } from 'types/search-result'

import styles from './Dropdown.module.css'

import { Icon } from '@wartek-id/icon'

const SUBMENU = 'submenu'

interface MenuProps {
  children: React.ReactNode
  className?: string
  onClick?: Function
  value?: number | string | SortType
  setTitle?: Function
  scrollTop?: number
  width?: number
}

interface MenuItemProps {
  children?: React.ReactNode
  type?: 'submenu'
  name: string
  id: number
  onClick?: Function
  value?: number | string | SortType
  setTitle?: Function
  scrollTop?: number
  width?: number
}

interface SubMenuProps {
  children: React.ReactNode
  name: string
  id: number
  onClick?: Function
  value?: number | string | SortType
  setTitle?: Function
  scrollTop?: number
  width?: number
}

export const Menu = (props: MenuProps) => {
  const ref = React.useRef(null)

  const classes = clsx(
    'absolute hidden text-gray-700 py-1 rounded-lg dropdown-menu',
    props.className,
    styles.dropdownChild
  )

  return (
    <>
      <style jsx>{`
        .dropdown-menu {
          margin-top: ${props.scrollTop}px;
          width: ${props.width + 2}px;
        }
        .dropdown-submenu {
          margin-left: ${props.width + 8}px;
        }
      `}</style>
      <div className={classes}>
        <ul ref={ref} className={styles.dropdownScroll}>
          {React.Children.map(props.children, (child: any) => {
            return React.cloneElement(child, {
              onClick: props.onClick,
              value: props.value,
              setTitle: props.setTitle,
              width: props.width,
            })
          })}
        </ul>
      </div>
    </>
  )
}

export const MenuItem = (props: MenuItemProps) => {
  const [position, setPosition] = useState(0)
  const ref = React.useRef(null)

  const handleClick = () => {
    props.onClick(props.id, props.name)
  }

  if (props.value === props.id.toString()) {
    props.setTitle(props.name)
  }

  const handleMouse = () => {
    const startPositions = ref.current.clientHeight
    const positions = ref.current.parentElement.scrollTop + startPositions
    if (ref.current.parentElement.scrollTop === 0) {
      setPosition(-1 * startPositions)
    } else {
      setPosition(-1 * positions)
    }
  }

  return (
    <li
      ref={ref}
      className={clsx(styles.dropdownParent, 'mx-2')}
      onMouseOver={handleMouse}
    >
      <span
        className={clsx(
          styles.dropdownList,
          'p-2 whitespace-no-wrap flex justify-between'
        )}
        onClick={handleClick}
      >
        <span>{props.name}</span>
        {props.type === SUBMENU && (
          <Icon as="i" color="default" fontSize="small">
            chevron_right
          </Icon>
        )}
      </span>
      {React.Children.map(props.children, (child: any) => {
        return React.cloneElement(child, {
          scrollTop: position,
          width: props.width,
        })
      })}
    </li>
  )
}

export const SubMenu = (props: SubMenuProps) => {
  return (
    <MenuItem {...props} type={SUBMENU}>
      <Menu {...props} className="dropdown-submenu">
        {props.children}
      </Menu>
    </MenuItem>
  )
}

export default Menu
