import {
  TRACKER_BASE_URL,
  TRACKER_PATH,
  TRACKER_KEY,
  APP_NAME,
  APP_VERSION,
  TRACKER_VERSION,
  TRACKER_NAME,
} from 'constants/app'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import DeviceDetector from 'device-detector-js'
import publicIp from 'react-public-ip'
import Cookies from 'js-cookie'

function InitTracker(event_name, ...additional_params) {
  var data
  const page_name = 'SEARCH_PAGE'

  if (typeof Cookies.get('sp') === 'undefined') {
    const deviceDetector = new DeviceDetector()
    const userAgent = navigator.userAgent
    const parse = deviceDetector.parse(userAgent)

    const tracker_data = {
      cookie_id: uuidv4(),
      screen_height: screen.height,
      screen_width: screen.width,
      operating_system: parse.os.name,
      browser_name: parse.client.name,
      device_type: parse.device.type,
      device_brand: parse.device.brand,
      referrer: document.referrer,
    }

    data = {
      id: uuidv4(),
      timestamp: new Date(),
      app_name: APP_NAME,
      app_version: APP_VERSION,
      event_name: event_name,
      event_version: TRACKER_VERSION,
      event_type: 'EVENT_TYPE_CREATED',
      event_data: [
        {
          key: 'cookie_id',
          value: tracker_data.cookie_id,
        },
        {
          key: 'operating_system',
          value: tracker_data.operating_system,
        },
        {
          key: 'browser_name',
          value: tracker_data.browser_name,
        },
        {
          key: 'device_type',
          value: tracker_data.device_type,
        },
        {
          key: 'device_brand',
          value: tracker_data.device_brand,
        },
        {
          key: 'screen_height',
          value: tracker_data.screen_height.toString(),
        },
        {
          key: 'screen_width',
          value: tracker_data.screen_width.toString(),
        },
        {
          key: 'referrer',
          value: tracker_data.referrer,
        },
        {
          key: 'page_name',
          value: page_name,
        },
        ...additional_params,
      ],
    }

    Cookies.set('sp', JSON.stringify(tracker_data), {
      path: '',
      sameSite: 'strict',
      secure: true,
    })
  } else {
    const tracker_data = JSON.parse(Cookies.get('sp'))

    data = {
      id: uuidv4(),
      timestamp: new Date(),
      app_name: APP_NAME,
      app_version: APP_VERSION,
      event_name: event_name,
      event_version: TRACKER_VERSION,
      event_type: 'EVENT_TYPE_CREATED',
      event_data: [
        {
          key: 'cookie_id',
          value: tracker_data.cookie_id,
        },
        {
          key: 'operating_system',
          value: tracker_data.operating_system,
        },
        {
          key: 'browser_name',
          value: tracker_data.browser_name,
        },
        {
          key: 'device_type',
          value: tracker_data.device_type,
        },
        {
          key: 'device_brand',
          value: tracker_data.device_brand,
        },
        {
          key: 'screen_height',
          value: tracker_data.screen_height.toString(),
        },
        {
          key: 'screen_width',
          value: tracker_data.screen_width.toString(),
        },
        {
          key: 'referrer',
          value: tracker_data.referrer,
        },
        {
          key: 'page_name',
          value: page_name,
        },
        ...additional_params,
      ],
    }
  }

  return JSON.stringify(data)
}

export async function ShootTracker(event_name, ...additional_params) {
  var getPosition = function () {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject)
      )
    } else {
      return new Promise((resolve) => resolve({}))
    }
  }

  publicIp.v4().then((result) => {
    const ip_address = result
    getPosition()
      .then(async (position: { coords }) => {
        if (position.coords) {
          const { coords } = position
          const lat = coords.latitude
          const long = coords.longitude

          const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TRACKER_KEY}`,
          }

          const payload = {
            event_name: `${TRACKER_NAME}_${TRACKER_VERSION}`,
            data: InitTracker(
              event_name,
              {
                key: 'ip_address',
                value: ip_address,
              },
              {
                key: 'lat',
                value: lat.toString(),
              },
              {
                key: 'long',
                value: long.toString(),
              },
              ...additional_params
            ),
          }

          try {
            const res = await axios.post(
              `${TRACKER_BASE_URL}${TRACKER_PATH}`,
              payload,
              {
                headers: headers,
              }
            )
            return res
          } catch (err) {}
        } else {
          // eslint-disable-next-line no-console
          console.log('Browser Anda tidak mendukung Geolocation')
        }
      })
      .catch((error) => {
        var msg = null
        switch (error.code) {
          case error.PERMISSION_DENIED:
            msg = 'Pengguna menolak untuk menggunakan Geolocation'
            break
          case error.POSITION_UNAVAILABLE:
            msg = 'Data lokasi tidak tersedia'
            break
          case error.TIMEOUT:
            msg = 'Waktu untuk mendapatkan lokasi pengguna melebihi batas waktu'
            break
          case error.UNKNOWN_ERROR:
            msg = 'Terjadi kesalahan'
            break
        }
        // eslint-disable-next-line no-console
        console.log(msg)
      })
  })
}
