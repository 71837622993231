import create, { State } from 'zustand'
import type { FilterSearchType } from 'types/search-result'

export interface FilterSearchStates extends State {
  filters: FilterSearchType
  setFilters: (filters: FilterSearchType) => any
}

export const useFilterSearchStore = create<FilterSearchStates>((set) => ({
  filters: {
    keyword: null,
    locationId: null,
    locationName: null,
    categoryId: null,
    sort: 'RELEVANCE',
    latitude: null,
    longitude: null,
  },
  setFilters: (filters) =>
    set((state) => ({ filters: { ...state.filters, ...filters } })),
}))
