import { AxiosResponse } from 'axios'
import { api } from 'configs/api'
import { GET_CATEGORY } from 'constants/api'
import { useQuery, UseQueryOptions } from 'react-query'
import { CategoryListItem } from 'types/category'
import type { APIResponse } from 'types/api'

export function useAPIGetCategory(
  queryOpts?: UseQueryOptions<AxiosResponse<APIResponse<CategoryListItem[]>>>
) {
  return useQuery<AxiosResponse<APIResponse<CategoryListItem[]>>>(
    'get-category',
    () => api().get(GET_CATEGORY),
    queryOpts
  )
}
