import React, { useState, useEffect, useRef } from 'react'
import clsx from 'clsx'

import type { SortType } from 'types/search-result'

import styles from './Dropdown.module.css'

import { Icon } from '@wartek-id/icon'

interface DropdownProps {
  id: string
  title: string
  children: React.ReactNode
  onChange: Function
  value: number | string | SortType
  icon?: React.ReactNode
}

export const Dropdown = (props: DropdownProps) => {
  const ref = useRef(null)

  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState('')

  const child = React.Children.only(props.children) as React.ReactElement

  const classes = clsx(
    'flex justify-between items-center rounded mr-5 pl-5 pr-3 mb-2 w-full',
    styles.dropdownButton,
    open && styles.dropdownButtonSelected
  )

  const handleClick = () => {
    setOpen(!open)
  }

  const handleClickMenu = (value: string, name: string) => {
    props.onChange({ id: props.id, value, name })
  }

  const handleClickOutside = (event) => {
    if (open && ref.current && !ref.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  return (
    <div
      key={props.id}
      className={clsx(
        styles.dropdownDivButton,
        open && styles.dropdownDivButtonSelected,
        'md:max-w-full lg:w-[230px]'
      )}
    >
      <button
        ref={ref}
        className={classes}
        onClick={handleClick}
        data-testid="btn-dropdown"
      >
        <span className="flex">
          {props.icon}
          <span
            className={clsx(
              'truncate',
              props.icon
                ? 'max-w-[255px] md:max-w-[120px] lg:max-w-[155px]'
                : 'max-w-[280px] md:max-w-[146px] lg:max-w-[176px]'
            )}
          >
            {title !== '' ? title : props.title}
          </span>
        </span>
        <Icon as="i" color="default" fontSize="small">
          unfold_more
        </Icon>
      </button>
      {React.cloneElement(child, {
        onClick: handleClickMenu,
        filterId: props.id,
        value: props.value,
        width: ref.current?.clientWidth,
        setTitle,
      })}
    </div>
  )
}

export default Dropdown
