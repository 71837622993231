import { AxiosResponse } from 'axios'
import { api } from 'configs/api'
import { GET_LOCATION } from 'constants/api'
import { useQuery, UseQueryOptions } from 'react-query'
import type { APIResponse } from 'types/api'
import type { LocationListItem } from 'types/location'

export function useAPIGetLocation(
  latitude?: string,
  longitude?: string,
  queryOpts?: UseQueryOptions<AxiosResponse<APIResponse<LocationListItem[]>>>
) {
  return useQuery<AxiosResponse<APIResponse<LocationListItem[]>>>(
    ['get-locations', latitude, longitude],
    () =>
      api().get(GET_LOCATION, {
        params: {
          latitude,
          longitude,
        },
      }),
    queryOpts
  )
}
