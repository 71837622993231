import SearchPage from 'app/SearchPage'
import React from 'react'
import Head from 'next/head'

const Search = () => {
  return (
    <>
      <Head>
        <title>Pencarian Produk</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <SearchPage />
    </>
  )
}

export default Search
