import React, { memo } from 'react'
import { BASE_URL_SIPLAH } from 'constants/app'

export const Footer = memo(() => {
  const fontColor = 'text-gray-500'
  const navLink = [
    { title: 'Home', link: BASE_URL_SIPLAH },
    { title: 'Kunjungi SIPLah', link: BASE_URL_SIPLAH + 'index.php/kunjungi' },
    { title: 'Pelajari SIPLah', link: BASE_URL_SIPLAH + 'index.php/pelajari' },
    { title: 'Ikuti SIPLah', link: BASE_URL_SIPLAH + 'index.php/ikuti' },
  ]
  const socmedLink = [
    {
      title: 'twitter',
      icon: '/assets/icons/twitter.svg',
      link: 'https://twitter.com/Kemdikbud_RI',
    },
    {
      title: 'facebook',
      icon: '/assets/icons/facebook.svg',
      link: 'https://www.facebook.com/SIPLah-Kemendikbud-103634151134921/',
    },
    {
      title: 'instagram',
      icon: '/assets/icons/instagram.svg',
      link: 'https://www.instagram.com/siplah_dikbud/',
    },
    {
      title: 'youtube',
      icon: '/assets/icons/youtube.svg',
      link: 'https://www.youtube.com/c/SIPLahKemdikbud',
    },
  ]
  return (
    <>
      <div className="h-24 bg-blue-50"></div>
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 px-5 md:px-20 py-12">
        <div>
          <span className="text-2xl font-semibold">SIPLah</span>
          <p className={`text-sm ${fontColor} mt-4 leading-normal`}>
            <strong className={fontColor}>Informasi lebih lanjut:</strong>
            <br />
            Sekretariat LPSE dan UKPBJ Kemendikbud
            <br />
            Gedung C Lantai 14 Kompleks Kemendikbud
            <br />
            Jl. Jenderal Sudirman Senayan Jakarta 10270
            <br />
            <strong className={fontColor}>Telepon:</strong> 021-5735440
            <br />
            <strong className={fontColor}>Fax:</strong> 021-5735440
            <br />
            <strong className={fontColor}>Email:</strong>{' '}
            Pemantauan.pbj@kemdikbud.go.id
            <br />
          </p>
        </div>
        <div className="pl-4 md:pl-20">
          <ul className={`text-sm ${fontColor}`}>
            {navLink.map((e, i) => (
              <li className="mb-3" key={i}>
                <a href={e.link} className="flex items-center mb-4">
                  <img
                    src="/assets/icons/right-chevron.svg"
                    alt="right"
                    className="blue-500 h-2 mr-1"
                  />
                  {e.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <span className="text-lg font-semibold">Media sosial SIPLah</span>
          <p className={`text-sm ${fontColor} mt-4`}>
            Subscribe dan follow media sosial SIPLah untuk mendapatkan informasi
            terkini
          </p>
          <div className="flex mt-4">
            {socmedLink.map((e, i) => (
              <a
                key={i}
                href={e.link}
                className="w-10 h-10 items-center bg-blue-600 justify-center flex rounded mr-2"
              >
                <img src={e.icon} alt={e.title} className="w-4 h-4" />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-blue-50 py-5">
        <div className="container mx-auto px-5 md:px-20 flex items-center text-center">
          <span className="text-gray-500 text-sm">
            Hak Cipta Kementerian Pendidikan dan Kebudayaan, Republik Indonesia
            © 2020
          </span>
        </div>
      </div>
    </>
  )
})

export default Footer
