import React, { useEffect } from 'react'
import { useFilterSearchStore } from 'stores/filter-search'

import { Dropdown, Menu, SubMenu, MenuItem } from 'components/Dropdown'

import { useAPIGetCategory } from 'api/category'
import { useAPIGetLocation } from 'api/location'

import { Icon } from '@wartek-id/icon'

const FilterSearch = (props) => {
  const filters = useFilterSearchStore((state) => state.filters)

  const { data: dataLocation } = useAPIGetLocation(
    filters.latitude,
    filters.longitude
  )
  const { data: dataCategory } = useAPIGetCategory()

  const dataSort = [
    { name: 'Paling Sesuai', id: 'RELEVANCE' },
    { name: 'Harga Tertinggi', id: 'PRICE_DESC' },
    { name: 'Harga Terendah', id: 'PRICE_ASC' },
  ]

  const renderMenu = (id, options) => {
    return options?.map((option) => {
      if (option.child?.length > 0) {
        return (
          <SubMenu key={option.id} id={option.id} name={option.name}>
            {renderMenu(id, option.child)}
          </SubMenu>
        )
      } else {
        return <MenuItem key={option.id} id={option.id} name={option.name} />
      }
    })
  }

  const filterOptions = [
    {
      id: 'locationId',
      title: 'Filter berdasarkan lokasi',
      options: dataLocation?.data.data,
      value: filters.locationId,
      icon: (
        <Icon as="i" color="default" fontSize="small" className="mb-1 mr-1">
          location_on
        </Icon>
      ),
    },
    // {
    //   id: 'sort',
    //   title: 'Urutkan hasil pencarian',
    //   options: dataSort,
    //   value: filters.sort,
    // },
    {
      id: 'categoryId',
      title: 'Kategori',
      options: dataCategory?.data.data,
      value: filters.categoryId,
    },
  ]

  useEffect(() => {
    const data = dataLocation?.data.data ?? []
    if (!!data.length) {
      data.map((location) => {
        if (location.isSelected) {
          props.handleChangeFilter({
            id: 'locationId',
            value: location.id,
            name: location.name,
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLocation])

  return (
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-6 gap-6 lg:items-center items-center">
      <div className="col-span-6 lg:col-span-4 mt-5 md:mt-10 flex flex-col lg:flex lg:flex-row items-start lg:items-center">
        <span className="text-grey mr-3">Urutkan</span>
        {dataSort.map((sortOption, index) => (
          <button
            key={index}
            className={`btn btn-label mr-3 mb-2 w-full justify-center ${
              sortOption.id === filters?.sort ? 'active' : ''
            }`}
            onClick={() =>
              props.handleChangeFilter({
                id: 'sort',
                value: sortOption.id,
                name: null,
              })
            }
          >
            {sortOption.name}
          </button>
        ))}
      </div>
      <div className="w-full col-span-6 lg:col-span-2 md:col-span-6 justify-self-start lg:justify-self-end">
        <div className={props.className}>
          {filterOptions.map((filterOption, indexFilter) => (
            <Dropdown
              key={indexFilter}
              id={filterOption.id}
              title={filterOption.title}
              icon={filterOption.icon}
              value={filterOption.value}
              onChange={props.handleChangeFilter}
            >
              <Menu>{renderMenu(filterOption.id, filterOption.options)}</Menu>
            </Dropdown>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FilterSearch
